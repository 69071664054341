import { Ontology } from "tulkas-lib/primitives";

export const ENVS = Object.freeze({
  dev: "dev",
  prod: "prod",
});

export const DEFAULT_S3_REGION = process.env.REACT_APP_DEFAULT_S3_REGION;
export const USABLE_FILE_TYPES = ["tif", "ln", "geojson", "lngj/"];
export const DEFAULT_INDEX_DIR = "s3://valar-airen/airen0/data/";

export const FINDER_WIDTH = 250;
export const SIGN_OUT_HEIGHT = 220;
export const DIR_PROPORTION = 0.8;
export const LAYER_PROPORTION = 0.2;

export const GLOBAL_ONTOLOGY_DICT = {
  tumor: { visible: true, color: "rgba(255, 0, 0, 1)" },

  // bladder specific
  bladder_cis: { visible: true, color: "rgba(132, 178, 133, 1)" },
  bladder_invasion: { visible: true, color: "rgba(0, 255, 0, 1)" },
  bladder_variant: { visible: true, color: "rgba(20, 188, 196, 1)" },

  // prostate specific
  prostate_benign_acini: { visible: true, color: "rgba(0, 255, 0, 1)" },

  // pancreas specific
  pancreas_panin: { visible: true, color: "rgba(128, 0, 0, 1)" },
  pancreas_benign_duct: { visible: true, color: "rgba(0, 255, 0, 1)" },
  pancreas_benign_acini: { visible: true, color: "rgba(0, 255, 0, 1)" },
  pancreas_benign_islets: { visible: true, color: "rgba(0, 255, 0, 1)" },

  // benign
  benign_liver: { visible: true, color: "rgba(0, 255, 0, 1)" },
  benign_intestine: { visible: true, color: "rgba(0, 255, 0, 1)" },
  benign_alveoli: { visible: true, color: "rgba(0, 255, 0, 1)" },
  benign_bone: { visible: true, color: "rgba(0, 255, 0, 1)" },

  // breast specific
  breast_benign: { visible: true, color: "rgba(0, 255, 0, 1)" },
  breast_dcis: { visible: true, color: "rgba(132, 178, 133, 1)" },

  // colorectal specific
  colorectal_benign_bruners: { visible: true, color: "rgba(0, 128, 0, 1)" },
  colorectal_adenoma: { visible: true, color: "rgba(128, 0, 0, 1)" },
  colorectal_high_grade_dysplasia: {
    visible: true,
    color: "rgba(128, 0, 0, 1)",
  },

  // global
  necrosis: { visible: true, color: "rgba(0, 0, 0, 1)" },
  lymphovascular_invasion: { visible: true, color: "rgba(255, 0, 0, 1)" },
  mucin: { visible: true, color: "rgba(0, 255, 0, 1)" },
  stroma: { visible: true, color: "rgba(0, 0, 255, 1)" },
  muscle: { visible: true, color: "rgba(0, 0, 128, 1)" },
  nerve: { visible: true, color: "rgba(0, 0, 255, 1)" },
  fat: { visible: true, color: "rgba(0, 0, 255, 1)" },
  immune_infiltrate: { visible: true, color: "rgba(255, 255, 255, 1)" },
  tls: { visible: true, color: "rgba(255, 0, 255, 1)" },
  lymph_node: { visible: true, color: "rgba(0, 0, 255, 1)" },
  cartilege: { visible: true, color: "rgba(102,51,153,1)" },

  // To be deleted
  nonviable: { visible: true, color: "rgba(0, 0, 0, 1)" },
  benign: { visible: true, color: "rgba(0, 0, 0, 1)" },

  background: { visible: true, color: "rgba(0, 0, 0, 1)" },
  mechanical: { visible: true, color: "rgba(0, 0, 0, 1)" },
  blood: { visible: true, color: "rgba(0, 0, 0, 1)" },
  wax: { visible: true, color: "rgba(0, 0, 0, 1)" },
  blurring: { visible: true, color: "rgba(0, 0, 0, 1)" },
  bubble: { visible: true, color: "rgba(0, 0, 0, 1)" },
  folds: { visible: true, color: "rgba(0, 0, 0, 1)" },
  pen: { visible: true, color: "rgba(0, 0, 0, 1)" },

  // General Cell
  neoplastic: { visible: true, color: "rgba(255, 0, 0, 1)" },

  // Pancreas Specific
  pancreas_acinar: { visible: true, color: "rgba(255, 0, 165, 1)" },
  pancreas_islets: { visible: true, color: "rgba(255, 165, 0, 1)" },
  // pancreas_benign_ductal: { visible: true, color: "rgba(255, 165, 0, 1)" },
  pancreas_benign_ductal: { visible: true, color: "rgba(0, 255, 0, 1)" },
  pancreas_panin: { visible: true, color: "rgba(255, 0, 0, 1)" },

  // Prostate Specific
  prostate_acinar: { visible: true, color: "rgba(255, 0, 165, 1)" },

  stromal: { visible: true, color: "rgba(0, 0, 255, 1)" },
  lymphocyte: { visible: true, color: "rgba(106, 168, 79, 1)" },
  endothelial: { visible: true, color: "rgba(0, 255, 255, 1)" },

  //Marker Plottable
  eosinophil: { visible: true, markers: false, color: "rgba(153, 0, 255, 1)" },
  neutrophil: {
    visible: true,
    markers: false,
    color: "rgba(255, 255, 255, 1)",
  },
  plasma: { visible: true, markers: false, color: "rgba(255, 255, 0, 1)" },
  macrophage: { visible: true, markers: false, color: "rgba(255, 153, 0, 1)" },

  mitosis: { visible: true, markers: false },
  apoptosis: { visible: true, markers: false },

  rbc: { visible: true, markers: false, color: "rgba(128, 0, 0, 1)" },
  none: { visible: true, markers: false, color: "rgba(0, 0, 0, 1)" },
  unlabeled: { visible: true, markers: false, color: "rgba(0, 0, 0, 1)" },
};
export const GLOBAL_ONTOLOGY = new Ontology(GLOBAL_ONTOLOGY_DICT);

export const INIT_MAP_BOUNDS = [
  [0, 0],
  [5128, 6190],
];
export const TILE_SIZE = 512;

export const SIGN_OUT_DIALOG = {
  dialogTitle: "Are you sure you want to sign out?",
  dialogText:
    "You will be logged out of your account and redirected to the login page.",
};
